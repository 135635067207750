import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import notify from './external/notify'
import mansory from './external/mansory.js'


import axios from 'axios'


import firebaseMessaging from './external/firebase'
Vue.prototype.$messaging = firebaseMessaging


// import { register } from 'register-service-worker'
// register('firebase-messaging-sw.js')


import vSelect from 'vue-select'
Vue.component('v-select', vSelect)


import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)


import Croppa from 'vue-croppa';
Vue.use(Croppa)


import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';
Vue.use(TawkMessengerVue, {
    propertyId : '64551687ad80445890eb5204',
    widgetId : '1gvm60145',
    customStyle : {
        visibility : {
            dekstop : {
                xOffset : '20',
                yOffset : '20',
                position : 'br'
            },

            mobile : {
                xOffset : 20,
                yOffset : 70,
                position : 'bl'
            }
        }
    }
});

Vue.config.productionTip = false

Vue.prototype.$http = axios
// axios.defaults.baseURL = 'http://localhost/realestate/index.php/'
// axios.defaults.baseURL = 'https://api.bigglandlord.com/'
axios.defaults.baseURL = 'https://bigglandlord.com/api/index.php'
axios.defaults.headers.common['Auth'] = '';

new Vue({
    router,
    notify,
    mansory,
    created() {
        document.title = "Bigglandlord - Realty made easy. An all-in-one realty solutions provider";
    },
    data() {
        return {
            c: {
                main: 'rgb(255, 128, 0)',
                trans: 'rgba(255, 128, 0, 0.2)',
                trans2: 'rgba(255, 128, 0, 0.4)'
            },
            // imgurl: 'http://localhost/realestate/uploads/images/',
            // docurl: 'http://localhost/realestate/uploads/documents/',
            // banurl: 'http://localhost/realestate/uploads/banners/',
            // tmpurl: 'http://localhost/realestate/uploads/tmp/',
            // dpurl: 'http://localhost/realestate/uploads/dp/',
            
            // imgurl: 'https://api.bigglandlord.com/uploads/images/',
            // docurl: 'https://api.bigglandlord.com/uploads/documents/',
            // banurl: 'https://api.bigglandlord.com/uploads/banners/',
            // tmpurl: 'https://api.bigglandlord.com/uploads/tmp/',
            // dpurl: 'https://api.bigglandlord.com/uploads/dp/',
            
            imgurl: 'https://bigglandlord.fra1.digitaloceanspaces.com/images/',
            docurl: 'https://bigglandlord.fra1.digitaloceanspaces.com/documents/',
            banurl: 'https://bigglandlord.fra1.digitaloceanspaces.com/banners/',
            tmpurl: 'https://bigglandlord.fra1.digitaloceanspaces.com/tmp/',
            dpurl: 'https://bigglandlord.fra1.digitaloceanspaces.com/dp/',


            role: [],
            agent: '',
            is_partner: '',
            partner: '',
            is_manager: '',
            current_appointment: '',
            user_id: '',
            login: false,
            firstname: '',
            lastname: '',
            dp: '',
            phone: '',
            whatsapp: '',
            email: ''
        }
    },
    render: h => h(App)
}).$mount('#app')
